import { Button, Typography } from '@vartanainc/design-system';
import {
  Ref,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { DebouncedFunc, get, startCase } from 'lodash';
import _debounce from 'lodash/debounce';
import { FormikProvider, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { removeTrailingZeros } from '../../../../utils/helpers';
import TermsDropdown from '../../../../designSystem/CompactFields/TermsDropdown';
import { CALCULATE_PROPOSED_PAYMENTS } from '../../../../graphql/queries/progen';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import CompactNumericField from '../../../../designSystem/CompactFields/CompactNumericField';
import SubsidyField from '../../../../designSystem/CompactFields/SubsidyField';
import CompactDropdownInput from '../../../../designSystem/CompactFields/CompactDropDown';
import { WidgetContext } from '../../../../context/WidgetContext';
import { useOrderCreation } from '../../../../utils/hooks/order_creation';
import {
  oneWholeTwoDecimal,
  WIDGET_WIDTH_VARIANTS,
} from '../../../../constants/common.constants';
import { MAX_ALLOWED_TCV } from '../../../Orders/order.constants';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { PAYOUT_LABELS } from '../../../CommonWidgetV2/commonWidget.constants';
import { WidgetWidthVariantType } from '../widgetV2.constants';

interface ErrorProps {
  message: string;
  subsidy: string;
}

interface CalculatorProps {
  onValueChange: (value: unknown) => void;
  setLoading: (value: boolean) => void;
  isInstallment?: boolean;
  calculatorRef: Ref<unknown>;
  setCanDownloadProposal: (canDownloadProposal: boolean) => void;
  onFrequencyChange?: (value) => void;
  setError?: (value) => void;
  error?: ErrorProps;
}

interface ProductProps {
  defaultPaymentTerm?: number;
  defaultPaymentFrequency?: string;
  defaultContractLength?: number;
  defaultSpiffRate?: number;
}

interface calculatorPayloadProps {
  current?: {
    [key: string]: string;
  };
}

interface BillingFrequency {
  value: string;
  label: string;
}

const Calculator = ({
  onValueChange,
  setLoading,
  isInstallment,
  calculatorRef,
  setCanDownloadProposal,
  onFrequencyChange,
  setError,
  error,
}: CalculatorProps): JSX.Element => {
  const widgetData = useContext(WidgetMetaContext);
  let { companyNumber } = useParams();
  const widgetContext = useContext(WidgetContext);
  const product = get(
    widgetData,
    'meta.calculator.defaultProductDetails',
    {}
  ) as ProductProps;

  if (!companyNumber) companyNumber = get(widgetContext, 'selectedCompany.number', '');
  const [maxSubsidy, setMaxSubsidy] = useState<string | null>('');
  const [availableCredit, setAvailableCredit] = useState('');
  const [tcvWarning, setTcvWarning] = useState(false);
  const [termWarning, setTermWarning] = useState(false);
  const [spiffWarning, setSpiffWarning] = useState(null);
  const [subsidyWarning, setSubsidyWarning] = useState(null);
  const [isCreditTruncated, setIsCreditTruncated] = useState(false);
  const [isSubsidyTruncated, setIsSubsidyTruncated] = useState(false);
  const [closeTermDropdown, setCloseTermDropdown] = useState(false);
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [filteredBillingFrequencies, setFilteredBillingFrequencies] = useState<
    BillingFrequency[]
  >([]);
  const { filterFrequencies } = useOrderCreation();

  const calculatorPayloadRef = useRef() as calculatorPayloadProps;

  const termOptions = useMemo(() => {
    const calculatorTerms = get(widgetData, 'meta.calculator.terms', []);

    // filtering duplicate terms in case of prorated terms
    return calculatorTerms
      .filter((term, index) => calculatorTerms.indexOf(term) === index)
      .map((term) => ({
        value: parseInt(term, 10),
        label: String(term).includes('co-term')
          ? `${String(term).replace('(co-term)', 'months (co-term)')}`
          : `${String(term)} months`,
      }));
  }, [widgetData]);

  const isSyndicatedOrder = useMemo(() => {
    return get(widgetData, 'meta.calculator.isSyndicated');
  }, [widgetData]);
  const disableTermsDropdown = termOptions.length < 2 && isSyndicatedOrder;

  const spiffMode = useMemo(() => {
    return get(widgetData, 'meta.calculator.spiffMode');
  }, [widgetData]);

  const currencySymbol = useMemo(() => {
    return get(widgetData, 'meta.calculator.currencySymbol');
  }, [widgetData]) as unknown as string;

  const [getProposalPayments] = useLazyQuery(CALCULATE_PROPOSED_PAYMENTS);

  const frequencyOptions: BillingFrequency[] = useMemo(() => {
    return get(widgetData, 'meta.calculator.frequency', []).map((frequency) => ({
      value: frequency,
      label: startCase(frequency),
    }));
  }, [widgetData]);

  const netTermOptions = useMemo(() => {
    let netTerms = get(widgetData, 'meta.calculator.netTerms', []).map((netTerm) => ({
      value: netTerm,
      label: netTerm === 0 ? 'Upon receipt' : `Net-${netTerm}`,
    }));
    if (!isInstallment) netTerms = netTerms.filter((netTerm) => netTerm.value !== 0);
    return netTerms;
  }, [isInstallment, widgetData]);

  const getInitialNetTerm = useCallback(() => {
    // this returns the default net-term if it exists in the approved net-terms
    return product.defaultPaymentTerm &&
      netTermOptions.find((option) => option.value === product.defaultPaymentTerm)
      ? product.defaultPaymentTerm
      : netTermOptions[0]?.value;
  }, [product.defaultPaymentTerm, netTermOptions]);

  const getInitialFrequency = useCallback(() => {
    // this returns the default frequency if it exists in the approved frequencies
    return product.defaultPaymentFrequency &&
      frequencyOptions.find((option) => option.value === product.defaultPaymentFrequency)
      ? product.defaultPaymentFrequency
      : frequencyOptions[0]?.value;
  }, [frequencyOptions, product.defaultPaymentFrequency]);

  const getInitialTerm = useCallback(() => {
    // this returns the default contract term if it exists in the approved terms
    return product.defaultContractLength &&
      termOptions.find((option) => option.value === product.defaultContractLength)
      ? product.defaultContractLength
      : termOptions[0]?.value;
  }, [product.defaultContractLength, termOptions]);

  const spiffDefaultValue = product.defaultSpiffRate || 0;

  const initialValues = useMemo(() => {
    return {
      totalContractValue: '',
      term: isInstallment && getInitialTerm(),
      netTerm: getInitialNetTerm(),
      billingFrequency: isInstallment ? getInitialFrequency() : undefined,
      subsidy: undefined,
      paymentType: isInstallment ? 'installments' : 'net_terms',
      isDollar: false,
      spiffRate: spiffMode === 'fixed' ? spiffDefaultValue * 100 : '',
    };
  }, [
    getInitialFrequency,
    getInitialNetTerm,
    getInitialTerm,
    isInstallment,
    spiffDefaultValue,
    spiffMode,
  ]);

  const handleCalculatorState = useCallback(
    (response, values): void => {
      calculatorPayloadRef.current = response;
      let subsidy = values.isDollar
        ? response?.dollar_max_subsidy
        : response?.max_subsidy_percentage;
      if (!values.isDollar && !response?.max_subsidy) subsidy = '';

      setMaxSubsidy(
        // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
        response?.dollar_max_subsidy === '$0.00' ? null : removeTrailingZeros(subsidy)
      );
      setTcvWarning(
        !!(get(response, 'errors.amount') || get(response, 'errors.message'))
      );
      setSubsidyWarning(get(response, 'errors.subsidy'));
      setTermWarning(get(response, 'errors.term'));
      setSpiffWarning(get(response, 'errors.spiff', ''));
      setAvailableCredit(get(response, 'available_credit'));
      const isEllipsis = document.getElementsByClassName('ellipsis');
      setIsCreditTruncated(isEllipsis[0]?.clientWidth / 16 > 6.95);
      setIsSubsidyTruncated(isEllipsis[1]?.clientWidth / 16 > 6.75);
      onValueChange(response);
    },
    [onValueChange]
  );

  const calculatePayments = useCallback(
    async (values) => {
      if (!values.totalContractValue || Number(values.totalContractValue) === 0) return;
      setLoading(true);
      setCloseTermDropdown(true);
      const subsidy = get(widgetData, 'meta.calculator.showSubsidyField', false)
        ? values.subsidy
        : null;
      const { data } = await getProposalPayments({
        variables: {
          paymentType: values.paymentType,
          billingFrequency: values.billingFrequency,
          term: values.term,
          paymentTerm: values.netTerm,
          dollarBlindDiscount: values.isDollar ? parseFloat(subsidy) : 0,
          percentageBlindDiscount: values.isDollar ? 0 : parseFloat(subsidy),
          amount: parseFloat(values.totalContractValue) || 0,
          companyNumber,
          isDollar: values.isDollar,
          spiffRate: parseFloat(values.spiffRate) || 0,
        },
      });
      setLoading(false);
      setCloseTermDropdown(false);
      handleCalculatorState(data?.calculateProposedPayments, values);
    },
    [companyNumber, getProposalPayments, handleCalculatorState, setLoading, widgetData]
  );

  const handleSubmit = (values): void => {
    calculatePayments(values);
    setCanDownloadProposal(values.totalContractValue > 0);
  };

  const Options = [
    { label: '%', value: '%' },
    { label: currencySymbol, value: '$' },
  ];

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  });

  // calls onFrequencyChange whenever the frequency is updated in formik
  useEffect(() => {
    if (onFrequencyChange)
      onFrequencyChange({ value: formikBag.values.billingFrequency });
  }, [formikBag.values.billingFrequency, onFrequencyChange]);

  const debouncedSubmitRef = useRef<DebouncedFunc<() => Promise<() => void>>>(
    _debounce(() => formikBag.submitForm(), 1000)
  );
  const tcvFieldRef = useRef<HTMLInputElement>(null);
  const spiffFieldRef = useRef<HTMLInputElement>(null);

  const debouncedSubmit = (): void => {
    if (debouncedSubmitRef.current) {
      debouncedSubmitRef.current();
    }
  };

  useEffect(() => {
    if (setError) {
      setError({ ...error, term: formikBag.errors.term });
    }
    // adding this because having error in the dependency array causes infinite re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.errors.term, setError]);

  const validateTcv = (): void => {
    if (!formikBag.values.totalContractValue) {
      setTcvWarning(true);
      formikBag.setFieldTouched('totalContractValue', true);
      tcvFieldRef.current?.focus();
    }
  };

  useImperativeHandle(calculatorRef, () => ({
    formRef: formikBag,
    validateTcv,
  }));

  const handleSubsidyApply = (): void => {
    formikBag
      .setFieldValue('subsidy', maxSubsidy?.replace(/[%, $]/g, ''))
      .then(() => debouncedSubmit());
  };

  const handleSubsidyUnitChange = async (): Promise<void> => {
    let subsidy = parseFloat(formikBag.values.subsidy || '0');
    const { isDollar, totalContractValue: totalContractFormValue } = formikBag.values;
    const totalContractValue = parseFloat(totalContractFormValue || '0');
    const {
      dollar_max_subsidy: dollarMaxSubsidy,
      max_subsidy_percentage: percentageMaxSubsidy,
    } = calculatorPayloadRef.current || {};
    const convertedMaxSubsidy = isDollar
      ? percentageMaxSubsidy || ''
      : dollarMaxSubsidy || '';

    if (totalContractValue) {
      // if converting to percentage then convert subsidy value to percentage as well
      if (isDollar)
        subsidy = parseFloat(((subsidy / totalContractValue) * 100).toFixed(2));
      // if converting to value then convert subsidy from percentage back to value
      else subsidy = parseFloat(((subsidy / 100) * totalContractValue).toFixed(2));
    }

    if (subsidy.toString().includes('.00')) subsidy = Math.round(subsidy);
    if (formikBag.values.subsidy !== '')
      await formikBag.setFieldValue('subsidy', `${subsidy}`);
    setMaxSubsidy(
      // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
      convertedMaxSubsidy === '$0.00' || convertedMaxSubsidy === '0.0%'
        ? ''
        : removeTrailingZeros(convertedMaxSubsidy)
    );
    await formikBag.setFieldValue('isDollar', !formikBag.values.isDollar);
    debouncedSubmit();
  };

  const getSubsidyClass = (): string => {
    const inputElement: HTMLInputElement | null =
      document.querySelector('.subsidy-input input');
    if (inputElement && !inputElement.value) return 'subsidy-input empty-subsidy';
    return 'subsidy-input';
  };

  /*
   if current selected billing frequency is not found in filteredBillingFrequencies
   then sets the 0th index value as billing frequency
  */
  useEffect(() => {
    if (filteredBillingFrequencies.length > 0 && formikBag.values.billingFrequency) {
      const foundObject = filteredBillingFrequencies.find(
        (frequency) => frequency.value === formikBag.values.billingFrequency
      );
      if (!foundObject) {
        formikBag.setFieldValue('billingFrequency', filteredBillingFrequencies[0].value);
      }
    }
    // added to avoid too many rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredBillingFrequencies, formikBag.values.billingFrequency]);

  useEffect(() => {
    if (
      (formikBag.values.term || formikBag.values.term === 0) &&
      !formikBag.errors.term
    ) {
      setFilteredBillingFrequencies(
        filterFrequencies(frequencyOptions, formikBag.values.term)
      );
      debouncedSubmit();
    }
    // added to avoid too many rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.term, formikBag.errors.term]);

  useEffect(() => {
    if (termOptions.length > 0 && isInstallment) {
      const updatedValidationSchema = validationSchema.clone().shape({
        term: Yup.number().required('Contract length is required'),
      });
      setValidationSchema(updatedValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termOptions, isInstallment]);

  const widthVariant = get(widgetContext, 'widthVariant', '') as WidgetWidthVariantType;

  const availableAmountLabel = useMemo(() => {
    if (widthVariant && widthVariant !== WIDGET_WIDTH_VARIANTS.xl)
      return PAYOUT_LABELS.availableAmount.small;
    return PAYOUT_LABELS.availableAmount.default;
  }, [widthVariant]);

  const maxSubsidyLabel = useMemo(() => {
    if (widthVariant !== WIDGET_WIDTH_VARIANTS.xl) return PAYOUT_LABELS.maxSubsidy.small;
    return PAYOUT_LABELS.maxSubsidy.default;
  }, [widthVariant]);

  return (
    <FormikProvider value={formikBag}>
      <form onSubmit={calculatePayments}>
        <div className="flex flex-col gap-2.5 calculator-container">
          <div className="flex flex-col gap-1.5 fields-parent-container">
            <div className="flex gap-x-1.5 fields-upper-container">
              <div className="flex-2 tcv-field">
                <CompactNumericField
                  label="Total contract value"
                  customPrefix={currencySymbol}
                  placeholder="0"
                  name="totalContractValue"
                  showError={!!tcvWarning}
                  showHoverShadow={false}
                  maxAllowedValue={MAX_ALLOWED_TCV}
                  onValueChange={(e) => {
                    formikBag
                      .setFieldValue('totalContractValue', e.value)
                      .then(() => debouncedSubmit());
                  }}
                  decimalScale={2}
                  ref={tcvFieldRef}
                />
              </div>

              {get(widgetData, 'meta.calculator.showSubsidyField', true) && (
                <div className="flex-2 subsidy-field">
                  <SubsidyField
                    className={`${getSubsidyClass()}`}
                    label="Subsidy"
                    options={Options}
                    name="subsidy"
                    customPrefix={formikBag.values.isDollar ? currencySymbol : ''}
                    placeholder="0"
                    showError={!!subsidyWarning}
                    onBlur={() => debouncedSubmit()}
                    onValueChange={(e) => {
                      formikBag
                        .setFieldValue('subsidy', e.value)
                        .then(() => debouncedSubmit());
                    }}
                    onUnitChange={(e) => {
                      handleSubsidyUnitChange();
                    }}
                    value={formikBag.values.subsidy || undefined}
                    decimalScale={2}
                    fixedDecimalScale={false}
                  />
                </div>
              )}

              {spiffMode !== 'none' && (
                <div className={`flex-1 ${spiffWarning ? 'is-error' : ''} spiff-field`}>
                  <CompactNumericField
                    label="SPIFF"
                    className="flex-1"
                    placeholder="0%"
                    customSuffix="%"
                    name="spiffRate"
                    maxAllowedValue={oneWholeTwoDecimal}
                    showHoverShadow={false}
                    showError={!!spiffWarning}
                    disabled={spiffMode === 'fixed'}
                    onValueChange={(e) => {
                      formikBag
                        .setFieldValue('spiffRate', e.value)
                        .then(() => debouncedSubmit());
                    }}
                    decimalScale={2}
                    ref={spiffFieldRef}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-x-1.5 fields-lower-container">
              {isInstallment && (
                <>
                  <TermsDropdown
                    name="term"
                    disabled={disableTermsDropdown}
                    label="Contract length"
                    id="term"
                    options={termOptions}
                    showError={!!termWarning || !!formikBag.errors.term}
                    isCompact
                    closeDropdown={closeTermDropdown}
                    handleCloseDropdown={setCloseTermDropdown}
                    resetOptionsOnBlur
                  />
                  <CompactDropdownInput
                    label="Frequency"
                    name="billingFrequency"
                    options={filteredBillingFrequencies}
                    onChange={() => {
                      formikBag.submitForm();
                    }}
                    disabled={filteredBillingFrequencies.length < 2}
                    showHoverShadow={false}
                  />
                </>
              )}
              <CompactDropdownInput
                name="netTerm"
                label="Net terms"
                options={netTermOptions}
                onChange={() => formikBag.submitForm()}
                disabled={netTermOptions.length < 2}
                showHoverShadow={false}
              />
            </div>
          </div>
          <div className="calculator-payout-container flex items-center self-stretch gap-4">
            <div className="flex justify-center items-center gap-[0.125rem]">
              <Typography variant="paragraph9" color="color-gray-140">
                {availableAmountLabel}
              </Typography>
              <div className="flex items-center max-w-[6.95rem] relative calculator-payout-value">
                {availableCredit ? (
                  <>
                    <Typography
                      variant="paragraph12"
                      bold
                      color="color-blue-180"
                      className="ellipsis tooltip-icon"
                    >
                      {availableCredit}
                    </Typography>
                    {isCreditTruncated && (
                      <div className="tooltip flex p-[0.75rem] justify-end items-end self-stretch bg-[#333333] rounded shadow-md absolute z-50 bottom-[1.7rem] left-[2rem]">
                        <Typography variant="paragraph12" color="color-white-100">
                          {availableCredit}
                        </Typography>
                      </div>
                    )}
                  </>
                ) : (
                  <SvgIcon
                    className="transform rotate-90"
                    name="more_vert"
                    width="1rem"
                    height="1rem"
                    fill="color-gray-40"
                  />
                )}
              </div>
            </div>
            {get(widgetData, 'meta.calculator.showSubsidyField', false) && (
              <div className="flex justify-center items-center gap-[0.125rem]">
                <Typography variant="paragraph9" color="color-gray-140">
                  {maxSubsidyLabel}
                </Typography>
                <div className="flex items-center max-w-[6.75rem] calculator-payout-value">
                  {maxSubsidy ? (
                    <>
                      <Typography
                        className="ellipsis tooltip-icon"
                        variant="paragraph12"
                        bold
                        color="color-blue-180"
                      >
                        {maxSubsidy}
                      </Typography>
                      {isSubsidyTruncated && (
                        <div className="tooltip flex p-3 justify-end items-end self-stretch bg-[#333333] rounded shadow-md absolute z-50 bottom-[1.7rem] left-[2rem]">
                          <Typography variant="paragraph12" color="color-white-100">
                            {maxSubsidy}
                          </Typography>
                        </div>
                      )}

                      <Button
                        size="xx-small"
                        backgroundColor="tertiary"
                        type="button"
                        onClick={handleSubsidyApply}
                        className="ml-1"
                      >
                        Apply
                      </Button>
                    </>
                  ) : (
                    <SvgIcon
                      className="transform rotate-90 shrink-0"
                      name="more_vert"
                      width="1rem"
                      height="1rem"
                      fill="color-gray-40"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Calculator;
